export const unique = <T = number | string | boolean>(items: T[]): T[] =>
  Array.from(new Set(items));

export const calculateAverageStep = (values: number[]): number => {
  if (values.length <= 1) {
    return 0; // No step if array has 0 or 1 element
  }

  // Sort the array
  const sortedNumbers = [...unique(values)].sort((a, b) => a - b);

  // Calculate sum of differences
  let sumOfDifferences = 0;
  for (let index = 1; index < sortedNumbers.length; index++) {
    sumOfDifferences += sortedNumbers[index] - sortedNumbers[index - 1];
  }

  // Calculate average
  return sumOfDifferences / (sortedNumbers.length - 1);
};
